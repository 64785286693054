  .card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    justify-content: center;

  }
  .img-left,
  .img-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 990px){
    .card-container {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 550px){ 
    .card-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .img-left,
    .img-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

  }
  .card-container h3 {
    color: #000;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .card-container p {
        
      text-align: justify;
      margin-left: 0;
      margin-bottom: 0;
      word-wrap: break-word;
  }
    
    .card-right {
      width: 100%;
      align-items: right;
      justify-content: right;
      padding: 1.5rem;
    }
    @media screen and (max-width: 990px){
      .card-right{
        display: block;
        padding-top: 2rem;
      }
      .card-righ img {
        width: 80%;
        align-item: center;
      }
    }
    .card-right img {
      width: 80%;
    }

    .card-left {
      width: 100%;
      padding: 1.5rem;
          
    }
  .card-left h2 {
    padding-bottom: 1rem;
  }

       
    .u-col {
      margin-top: 2rem;
    }
    .u-container-layout {
        position: relative;
        
        flex: 1;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        
    }
    .u-layout-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2.5rem;

    } 
    .u-layout-row  span .u-icon-u {
      font-size: 5rem;
      color: red;
      width: 4rem;
    }
      @media screen and (max-width: 550px){
        .u-col .u-layout-row {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 2.5rem;
        }
        .u-container-layout {
            position: relative;
            
            flex: 1;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .card-right {
          display: grid;
          grid-template-columns: 1fr;
          margin: 1rem;  
          width: 100%;
          align-items: center;
          justify-content: center;
          
        }
        .img-left {
          padding-bottom: 3rem;
        }

        
      }  















.abt-sec-second {
  
  padding: 5rem 2rem;
  margin-bottom: 5rem;
}

.abt-sec {
    font-size: 16px;
    line-height: 30px;
    overflow: hidden;
}

  .vision__arr,
  .vision-tar {
    font-size: 1.8rem;
    color: red;
    margin: 0.5rem;
  }
  
  .abt-sec.style2 {
    padding-top: 5rem;
    padding-bottom: 10px;
  }
  
  .abt-sec.style2 .main-heading {
    padding-top: 0;
    margin: -4px 0 48px;
    color: #ff0000;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3rem;
  }
  
  .abt-sec .main-heading {
    padding-top: 4px;
    margin: 0 0 48px;
  }
  
  .abt-sec .main-heading:before {
    left: 0;
    transform: none;
  }

  
.abt-sec .main-heading::before {

  left: 0;
  transform: none;

}
#back-top, .promo-box, .btn:hover, .btn.active, .fancybox-skin, #header .nav-opener, .checkout-sec .btn, .error-page .btn, .signup-form .btn, #footer h3::before, .coming-sec .socail-network a:hover, .main-heading::before, .main-heading3::before, .team-sec .team .over, .cart-sec .btn:hover, .shop-detail .holder .btn, #header.style2 .header-area, #header.style3 .header-area, .cart-sec .cart-list .btn, .cart-sec .coupon-form .btn:hover, .contact-sec .contact-form .btn, .comment-form .contact-form .btn, .contact-sec .contact-list::before, .faq-sec .accordion .active .opener::before, .main-slider .slick-dots li button:hover, .main-slider .slick-dots .slick-active button, .blog-sec .blog .img-holder .over span::before, .blog-sec .blog .img-holder .over span::after {

  background-color: #ff0000;

}

.main-heading {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin: 0 0 60px;
  padding: 0 0 28px;
      padding-top: 0px;
  position: relative;
  z-index: 1;
}

.main-heading::before {
  position: absolute;
  content: "";
  z-index: -1;
  left: 50%;
  top: auto;
  bottom: 0;
  right: auto;
  transform: translateX(-50%);
  width: 70px;
  height: 4px;
}
  
  .abt-sec p {
    /* margin: 0 0 34px; */
    text-align: justify;
    line-height: 2.8rem;
   
    margin-left: 0;
    font-size: 2rem;
    margin-bottom: 0;
  
  }
  
  .abt-sec .read-more {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-decoration: underline;
    transition: all 0.25s linear;
  }
  
  .abt-sec .read-more:hover {
    color: #111;
    text-decoration: none;
  }
  
  .abt-sec .img-holder {
    float: right;
    width: 310px;
  }
  
  .abt-sec .holder {
    margin-bottom: 58px;
  }