


.navbar {
    position: relative;
    background: linear-gradient(90deg, #ff0000 0%, #ff0000 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
}
.navbar__body-web {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 5rem;
  height: 100%;

}


  
.navbar .fa, .fas {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  width: 140px;
}
  
  
  
.nav-menu {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: right;
  width: 70vw;
  justify-content: right;
  margin-right: 2rem;
}
  
.nav-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
}

.dropdown {
    font-size: 1.8rem;
}

@media screen and (max-width: 700px) {
  dropdown {
      display: none;
  }

  .navbar-logo {
    width: 100px;
}
}
  
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: capitalize;
}

.nav-links:hover {
  background-color: var(--light-gold-secondary);
  color: #ff0000;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
  
@media screen and (max-width: 960px) {
    .fa-caret-down {
        display: none;
    }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ff0000;
    top: 1;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 999;
    height: 50vh;
  }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      text-transform: capitalize;
    }
    
    .nav .nav-links:hover {
      background-color: #fff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: var(--light-gold-secondary);
      text-decoration: none;
      color: #000;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #ff0000;
      transition: 250ms;
    }
  
    button {
      display: none;
    }
  }

