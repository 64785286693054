.slider .slide--item {
    height: 800px;
}

.bg-theme1 {
    position: relative;
}

.bg-theme {
    text-shadow: 1px 1px 5px black;
}

.bg-theme::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('./../../img/heavy.jpg');

    z-index: -1;
}

.bg-theme::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.9;
    background-color: #3498db;


    z-index: -1;
}

.bg-theme1::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('./../../img/milling.jpg');
    background-size: cover;
    z-index: -1;
}

.bg-theme1::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.9;
    background-color: #3498db;


    z-index: -1;
}

.slider .slide--headline,
.slider .slide--headline h1 {
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 5rem;
    font-weight: 400;
    line-height: 60px;
    margin-bottom: 27px !important;
    padding-bottom: 10px !important;
    text-transform: capitalize;
}

.slider .slide--bio {
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 29px;
}

.container {
    display: block !important;

}

.landing-con {
    display: block !important;
    max-width: 1500px !important;
}

.row {
    justify-content: space-between;
    align-items: center;

}

.rows {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    justify-content: center;
    margin-left: -15px;
}

.heading .heading--title {
    font-family: 'Raleway', sans-serif;
    color: #222222;
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 30px;
    text-transform: capitalize;
}

.landing-section {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
}

.feature-panel .feature--content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
}
.feature-panel .feature--contents  {
    margin-top: 2rem !important;
 }
.feature-panel .feature--contents p {
    font-size: 16px;
    /* font-weight: 400; */
    line-height: 24px;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
}


.feature-panel .feature--content h3 {
    font-family: 'Raleway', sans-serif;
    color: #222222;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 19px;
    text-transform: capitalize;
}

.special-purpose {
    background: #f5f8fa !important;
    border: none;
    border-radius: 5px solid transparent;
    margin: 2rem 0;
    padding: 2rem 2rem !important;
}

.landing-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;
}




.landing-form {
    width: 400px;
}



.input-landing {
    width: 100% !important;
}

.input-group {
    justify-content: space-between;
}

.submit-landing {
    font-size: 14px;
    border: none;
    background-color: white;
    padding: 10px 30px;
}

.landing-padding {
    padding: 40px;
    border-radius: 7px;
    cursor: pointer;
    margin: 40px;
    color: white;
    background-color: #3498db;
    box-shadow: 1px 1px 9px grey;
    width: 400px;

}

.testimonial--author h5 {
    color: white;
}

.testimonial--body p {
    color: white;
}

.landing-padding:hover {
    /* background-color: #3498db; */
    transition: all 0.5s ease;
    transform: scale(1.1);

}

.landing-r-width {
    /* width: 2000px!important; */
}

.for-landing-review {
    /* max-width: 2000px!important; */

}

@media (max-width: 600px) {
    .landing-form {
        width: 220px;
    }

    .input-group {
        display: block !important;
    }

    .m-landing {
        margin-bottom: 30px;
    }

    .slider .slide--headline,
    .slider .slide--headline h1 {
        color: #ffffff;
        font-family: 'Raleway', sans-serif;
        font-size: 25px;
        font-weight: 400;
        line-height: 60px;
        margin-bottom: 27px;
        text-transform: capitalize;
    }

    .landing-extra {
        margin: 0px !important;
        text-align: center;
        flex-wrap: wrap;

    }

    .landing-extra-card {
        padding-bottom: 30px !important;
    }

    .landing-btns {

        margin: auto;
    }

}

.landing-top-logo {
    width: 700px;
}

.mb-60 {
    margin-bottom: 60px;
}



























.landing-card div h1 {
    color: black;
    font-size: 4rem;
}

.landing-card {
    display: flex;
    align-items: center;
}

.landing-extra-card div h2 {
    color: black;
}

.landing-extra {
    margin: 0px 100px;
    display: flex;
}

.landing-extra-card {
    width: 500px;
    padding: 0px 50px;
}

.landing-extra-card:hover {
    cursor: pointer;
    transition: all 0.5s ease;
    transform: scale(0.9);
}


.landing-btns {

    cursor: pointer;
    padding: 1rem 3rem;
    border-radius: 2rem;
    background-color: var(--light-gold);
    outline: none;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: inherit;
    display: block !important;

    border: lighter;
}

.vissit {
    margin: auto;
    margin-bottom: 100px;
    margin-top: 100px;
    display: block;

}