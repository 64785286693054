.showdown-menu {
    width: 15rem;
    position: absolute;
    top: 67px;
    list-style: none;
    text-align: start;
    z-index: 1;
}
  
  .showdown-menu li {
    background: red;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  .showdown-menu li a{
    font-size: 1.5rem;
    font-weight: 700;
    color: white;

  }
  .showdown-menu li a:hover{
    color: #ff0000;

  }
  
  .showdown-menu li a:hover {
    background: var(--light-gold-secondary);
  }
  
  .showdown-menu.clicked {
    display: none;
  }
  
.dropdown-link {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    

}



