img {
    max-width: 100%;
    height: auto;
    display: block;
}
  
iframe {
border: 0;
}

.dyes-container {
    padding-top: 1rem;
}



@media screen and (max-width: 1000px) {
.column-60,
.column-40 {
    width: 100%;
    float: none;
    padding: 0;
}
#image-div img {
    margin: auto;
}
}

.btn {
text-transform: uppercase;
font-weight: 600;
color: #fff;
background-color: #5d63ed;
padding: 1.7rem 2.5rem;
margin: 1.5rem 0;
height: 5rem;
border: 0;
border-radius: 5px;
cursor: pointer;
outline:none;
}

.btn:hover {
background-color: #2f1e84;
transition: background-color 1s;
}

.logo-img {
width: 100%;
max-width: 200px;
}

@media (max-width: 650px) {
.logo-img {
    margin: 0 auto;
}
}

.container {
display: flex;
flex-direction: row;
justify-content: center;
}

.card {
display: flex;
flex-direction: column;
align-items: left;
text-align: left;
margin: 1.5rem;
padding: 3rem 2rem 3rem 2rem;
border: 1px solid rgba(55, 56, 58, 0.1);
border-radius: 5px;
}

@media (max-width: 800px) {
.container {
    flex-direction: column;
}

.card {
    width: 100%;
    margin: 1.5rem auto;
}
}

.card:hover {
box-shadow: 0 0 2rem -0.7rem rgba(55, 56, 58, 0.2);
border: 1px solid #ced4ee;
}

.icon {
width: 50px;
}

.desc > h3 {
margin: 2rem 0 2rem 0;
}



/* Hero */

#hero {
/*background-color: #4a4fbd;*/
background:linear-gradient(135deg, #e9e8e8 0%,#ffffff 100% );
color: #fff;
padding: 5rem 5rem;
}


h1{
font-size: 4rem;
padding-bottom: 5rem;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 5rem;
    padding: 5rem 5rem;
}

.grid img {
    width: 90%;
}

@media (max-width: 1000px) {
    #hero {
        margin-bottom: 2rem;
        padding: 1rem 2rem;
    }
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
        grid-gap: 5rem;
        padding-top: rem;
    }
}
@media (max-width: 800px) {
    #hero {
        margin-bottom: 2rem;
    }
    .grid {
        display: block;
        justify-content: center;
        align-items: center;
        grid-gap: 5rem;
        width: 100%;
    }
}

@media (max-width: 650px) {

.grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
    padding: 1rem 1rem;
}
}

#description {
font-size: 2rem;
margin-bottom: 5rem;
color: rgb(27, 27, 27);
font-weight: 500;
}

#hero .btn {
background-color: Transparent;
border: 2px solid #fff;
}

#hero .btn:hover {
background-color: #fff;
transition: background-color 1s;
color: #2f1e84;
transition: color 1s;
}

@media (max-width: 650px) {
#hero-img {
    margin: 2rem auto !important;
}
}

/* Why section */

#why {
text-align: left;
}

#why-container {
    padding: 5rem 8rem;
}
#why-container p{
    padding: 2rem 0;
}

@media (max-width: 1000px) {}
@media (max-width: 800px) {}
@media (max-width: 650px) {
    #why-container {
        padding: 5rem 2rem;
    }
}
/* Features section */

#features {
padding: 15rem 5rem 5rem 5rem;
text-align: center;
}

#features ol {
margin: 1.5rem 3.5rem;
}

#features ol > li {
margin-bottom: 1.5rem;
text-align: left;
display: flex;
flex-direction: column;
}

#features h3::before {
content: "";
font-family: FontAwesome;
color: #5d63ed;
display: inline-block;
margin-left: -2em;
width: 2em;
}

#pills::before {
content: "\f0f3" !important;
}

#med-report::before {
content: "\f15b" !important;
}

#family::before {
content: "\f0c0" !important; 
}

#doctor-icon::before {
content: "\f0f0" !important; 
}

/* Info section */
#info {
margin: 5rem 0;
padding: 8rem 6.5rem;
background-color: #f8f8f8;
display: flex;
justify-content: space-between;
align-items: center;
}

#info > div {
width: 80%;
padding-right: 5rem;
align-self: flex-start;
}

@media (max-width: 1000px) {
    #info {
        flex-direction: column;
        align-items: center;
    }

    #info > div {
        width: 100%;
        padding: 0;
        margin-bottom: 3rem;
    }

    #info h2 {
        text-align: center;
    }
}

#info > iframe {
max-width: 560px;
width: 100%;
border-radius: 5px;
box-shadow: 0 1rem 1rem -1rem rgba(55, 56, 58, 0.4);
}

/* Download section */

